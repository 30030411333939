import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

import { Accordion, Row, Col } from 'react-bootstrap';
import Heading from '@components/heading';
import Button from '@components/button';
import CurrencyText from '@components/currencyText';
import ShareButtons from '@components/shareButtons';
import Scrollbar from '@components/scrollbar';
import Layout from '@components/layout';
import SEO from '@components/seo';
import { eventInsider } from '../utils/insiderUtils';

import '@scss/_campaign-detail.scss';

export default function CampaignDetail({ location, pageContext }) {
  const [faqTitle, setFaqTitle] = useState(false);
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `,
  // );
  const title = 'Kampanyalar & Duyurular - Tıkla Gelsin';
  const description = "Tıkla Gelsin'nin tüm kampanya ve duyurularını hemen bu sayfadan takip edin.";
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: location.pathname,
    name: title,
    description,
  };

  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    const brandName = pageContext?.campaign?.details?.acceptableRestaurants?.[0].Name || '';
    eventInsider('campaign_detail_page_view', {
      product_id: pageContext?.campaign?.id,
      name: pageContext?.campaign?.title,
      taxonomy: [brandName],
      currency: 'TRY',
      unit_price: pageContext?.campaign?.details?.price,
      unit_sale_price: pageContext?.campaign?.details?.discountPrice,
      custom: {
        brand: brandName,
      },
    });
  }, []);

  function dateDiff() {
    const dateNow = dayjs(Date.now());
    const dateEnd = dayjs(pageContext?.campaign?.endDate);
    const diffDate = dateEnd?.diff(dateNow, 'day');
    return diffDate;
  }

  function discountValue() {
    const price = pageContext?.campaign?.details?.price;
    if (price) {
      const discountPrice = pageContext?.campaign?.details?.discountPrice;
      const percentDiff = ((price - discountPrice) / price) * 100;
      return percentDiff?.toFixed(0);
    }
    return null;
  }

  function handleFaqItemTitleClick(index) {
    if (index === faqTitle) {
      return setFaqTitle('');
    }
    return setFaqTitle(index);
  }

  function addToBasket() {
    const brandId = pageContext?.campaign?.details?.products[0]?.BrandCrmId;
    const productId = pageContext?.campaign?.details?.products[0]?.CrmId;
    if (pageContext?.campaign?.details?.routeLink) {
      window.location.href = pageContext?.campaign?.details?.routeLink;
    }
    if (productId) {
      window.location.href = `/a/cproduct?brandId=${brandId}&productId=${productId}`;
    }
    if (brandId) {
      window.location.href = `/a/cproduct?brandId=${brandId}`;
    }
  }

  function buttonTitleRenderer() {
    if (pageContext?.campaign?.details?.products?.length > 1) {
      return 'Markaya Git';
    }
    if (pageContext?.campaign?.details?.routeLink) {
      return 'Kampanyayı Gör';
    }
    return 'Sepete Ekle';
  }

  function generateOnlyText() {
    if (
      pageContext?.campaign?.details?.products?.length === 1 &&
      pageContext?.campaign?.details?.discountPrice !== null
    ) {
      if (pageContext?.campaign?.details?.howManyPerson > 1) {
        return (
          <div className="campaign-detail-card-menu-options">
            {`Kişi başı sadece `}
            <strong>
              <CurrencyText>{pageContext?.campaign?.details?.pricePerPerson}</CurrencyText>
            </strong>
          </div>
        );
      }
      if (pageContext?.campaign?.details?.discountPrice !== 0) {
        return (
          <div className="campaign-detail-card-menu-options">
            {`Sadece `}
            <strong>
              <CurrencyText>{pageContext?.campaign?.details?.discountPrice}</CurrencyText>
            </strong>
          </div>
        );
      }
    }
    return null;
  }

  return (
    <>
      <Layout sidebar={false} location={location}>
        <SEO
          title={title}
          siteUrl={location?.pathname}
          schemaMarkup={schema}
          description={description}
          deepLinkUrl={`campaigndetail/0/${pageContext?.campaign?.Slug}`}
        />
        <Row>
          <Col className="pl-0 pr-0">
            <div className="campaign-detail">
              <Heading className="page-title campaign-detail-page-title">{pageContext?.campaign?.title}</Heading>
              <div className="campaign-detail-card">
                <div className="campaign-detail-card-cover">
                  <img
                    src={pageContext?.campaign?.details?.image?.url}
                    alt={pageContext?.campaign?.title}
                    className="campaign-detail-card-cover-image"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div className="campaign-detail-card-content">
                  <div className="campaign-detail-card-content-section">
                    <div className="campaign-detail-card-content-section-inner">
                      <div className="campaign-detail-card-content-section-left">
                        <Heading type="h5" className="campaign-detail-card-content-section-title">
                          Kampanya İçeriği
                          {/* {translate('campaigns.campaignContentTitle')} */}
                        </Heading>
                        {pageContext?.campaign?.menuOptions?.map((item, index) => (
                          <div className="campaign-detail-card-menu-options">
                            <strong>
                              {item?.product?.Name}
                              {index + 1 !== pageContext?.campaign?.menuOptions?.length ? ' + ' : ''}
                            </strong>
                          </div>
                        ))}
                        {generateOnlyText()}
                      </div>
                      <div className="campaign-detail-card-content-section-right">
                        {pageContext?.campaign?.details?.discountPrice !== 0 && (
                          <div className="campaign-detail-card-price">
                            <div className="campaign-detail-card-price-old">
                              <CurrencyText>{pageContext?.campaign?.details?.price}</CurrencyText>
                            </div>
                            <div className="campaign-detail-card-price-new">
                              <CurrencyText>{pageContext?.campaign?.details?.discountPrice}</CurrencyText>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {pageContext?.campaign?.descriptionList?.length > 0 && (
                      <ul className="campaign-detail-card-description-items">
                        {pageContext?.campaign?.descriptionList?.map((item, index) => (
                          <li
                            className="campaign-detail-card-description-item"
                            key={`campaign-detail-card-description-item-${index + 1}`}
                          >
                            <i className={`icon-${item?.icon?.Icon}`} /> {item?.text}
                          </li>
                        ))}
                      </ul>
                    )}
                    <ul className="campaign-detail-card-tag-items">
                      {discountValue() && (
                        <li className="campaign-detail-card-tag-item campaign-card-tag-item--primary">
                          % {discountValue} İndirim
                          {/* {translate('campaigns.discountText', {
                          discountValue: discountValue(),
                        })} */}
                        </li>
                      )}
                      {dateDiff() < 1 ? (
                        <li className="campaign-card-tag-item campaign-card-tag-item--secondary">Son Gün</li>
                      ) : (
                        <li className="campaign-card-tag-item campaign-card-tag-item--secondary">
                          Son {dateDiff()} Gün
                          {/* {translate('campaigns.endDateText', {
                    dateDiff: dateDiff(),
                  })} */}
                        </li>
                      )}
                    </ul>
                  </div>
                  {pageContext?.campaign?.details?.howManyPerson > 1 && (
                    <div className="campaign-detail-card-content-section">
                      <div className="campaign-detail-card-content-section-left">
                        <Heading type="h5" className="campaign-detail-card-content-section-title">
                          {pageContext?.campaign?.details?.howManyPerson} Kişilik
                          {/* {translate('campaigns.forPerson', {
                          howManyPerson: pageContext?.campaign?.details?.howManyPerson,
                        })} */}
                        </Heading>
                        <p className="campaign-detail-card-price-per-person">
                          <span>{`Kişi başı `}</span>
                          {/* {translate('campaigns.startPerPersonText')} */}
                          <strong>
                            <CurrencyText>{pageContext?.campaign?.details?.pricePerPerson}</CurrencyText>
                          </strong>
                          <span>
                            {` 'den başlayan fiyatlarla`}
                            {/* {translate('campaigns.endPerPersonText')} */}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="campaign-detail-card-content-section">
                    <div className="campaign-detail-card-content-section-left">
                      <Heading type="h5" className="campaign-detail-card-content-section-title">
                        <span>Geçerli Markalar</span>
                      </Heading>

                      {pageContext?.campaign?.details?.acceptableRestaurants?.map((item) => (
                        <img
                          className="active-campaign-img "
                          src={item.Logo.url}
                          alt={item.Name}
                          width="50"
                          height="50"
                        />
                      ))}
                    </div>
                  </div>
                  <div className="campaign-detail-card-content-section campaign-detail-card-content-section--flex-col">
                    <div className="campaign-detail-card-content-section-inner">
                      <div className="campaign-detail-card-content-section-left">
                        <Heading type="h5" className="campaign-detail-card-content-section-title">
                          Kampanya Geçerlilik Tarihi
                          {/* {translate('campaigns.campaignValidityDate')} */}
                        </Heading>
                        <div className="campaign-detail-card-date-info">
                          <span>{`${dayjs(pageContext?.campaign?.startDate)
                            .locale('tr')
                            .format('DD MMMM YYYY')}`}</span>
                          <span>-</span>
                          <span>{`${dayjs(pageContext?.campaign?.endDate).locale('tr').format('DD MMMM YYYY')}`}</span>
                        </div>
                      </div>
                      <div className="campaign-detail-card-content-section-right">
                        <Heading type="h5" className="campaign-detail-card-content-section-title">
                          Paylaş
                          {/* {translate('campaigns.shareTitle')} */}
                        </Heading>
                        <ShareButtons
                          socialConfig={{
                            config: {
                              url: location.href,
                              title: pageContext?.campaign?.title,
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {pageContext?.campaign?.details?.campaignInfo && (
                    <div className="campaign-detail-card-content-section campaign-detail-card-content-section--no-border">
                      <div className="campaign-detail-card-content-section-left">
                        <Heading type="h5" className="campaign-detail-card-content-section-title">
                          Kampanya Bilgileri
                          {/* {translate('campaigns.campaignInfoTitle')} */}
                        </Heading>
                        <Scrollbar className="campaign-detail-card-campaign-info">
                          {pageContext?.campaign?.details?.campaignInfo}
                        </Scrollbar>
                      </div>
                    </div>
                  )}
                  <div className="campaign-detail-card-add-basket campaign-detail-card-add-basket--desktop">
                    <Button className="campaign-detail-card-add-basket-btn" onPress={() => addToBasket()}>
                      {buttonTitleRenderer()}
                    </Button>
                  </div>
                </div>
              </div>
              {pageContext?.campaign?.campaignTerms?.length > 0 && (
                <div className="campaign-detail-faq">
                  <Heading type="h2" className="page-title campaign-detail-faq-title">
                    Sıkça Sorulan Sorular
                    {/* {translate('campaigns.campaignFaqTitle')} */}
                  </Heading>

                  <Accordion className="campaign-detail-faq-items">
                    {pageContext?.campaign?.campaignTerms?.map((item, index) => (
                      <div className="campaign-detail-faq-item" key={`campaign-detail-faq-item-${index + 1}`}>
                        <Accordion.Toggle
                          as="div"
                          eventKey={item.title}
                          className={
                            faqTitle === item.title
                              ? 'campaign-detail-faq-item-title campaign-detail-faq-item-title--opened'
                              : 'campaign-detail-faq-item-title'
                          }
                          onClick={() => handleFaqItemTitleClick(item.title)}
                        >
                          {item?.title}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={item.title} className="campaign-detail-faq-item-content">
                          <p>{item?.content}</p>
                        </Accordion.Collapse>
                      </div>
                    ))}
                  </Accordion>
                </div>
              )}
              <div className="campaign-detail-card-add-basket campaign-detail-card-add-basket--mobile">
                <Button className="campaign-detail-card-add-basket-btn" onPress={() => addToBasket()}>
                  {pageContext?.campaign?.details?.products?.length > 1 ? 'Markaya Git' : 'Sepete Ekle'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

CampaignDetail.propTypes = {
  pageContext: PropTypes.any,
  location: PropTypes.object,
};
