import React from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

import '@scss/_share-buttons.scss';

const ShareButtons = ({ socialConfig }) => (
  <nav className="share-button">
    <ul className="share-button-items">
      <li className="share-button-item">
        <FacebookShareButton
          url={socialConfig?.config.url}
          title={socialConfig?.config.title}
          quote={socialConfig?.config.title}
          className="share-button-item-icon share-button-item-icon--facebook"
        />
      </li>
      <li className="share-button-item">
        <TwitterShareButton
          url={socialConfig?.config.url}
          title={socialConfig?.config.title}
          quote={socialConfig?.config.title}
          className="share-button-item-icon share-button-item-icon--twitter"
        />
      </li>
      <li className="share-button-item">
        <WhatsappShareButton
          url={socialConfig?.config.url}
          title={socialConfig?.config.title}
          quote={socialConfig?.config.title}
          className="share-button-item-icon share-button-item-icon--whatsapp"
        />
      </li>
    </ul>
  </nav>
);

ShareButtons.propTypes = {
  socialConfig: PropTypes.shape({
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ShareButtons;
